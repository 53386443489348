import React, { Suspense, lazy, useEffect, useRef, useState } from 'react';
import Page from './routes/Page';
import { useCommunity } from "./context/community";
import { setConfiguration } from 'react-grid-system';
import { usePopupNotification } from 'context/PopupNotificationContext';

const RedirectSuccess = lazy(() => import('components/RedirectSuccess/RedirectSuccess'));
const LazySentry = lazy(() => import('LazySentry'));

setConfiguration({ maxScreenClass: 'xl' });

const App = () => {
    const { loading, community: { domain } } = useCommunity();
    const { showNotification, hideNotification } = usePopupNotification();
    const notificationKey = useRef(null);
    const setTimer = useState(null)[1];

    useEffect(() => {
        console.info("ENV", process.env.NODE_ENV);
        window.addEventListener('error', handleNetworkError);
        window.addEventListener('offline', handleNetworkError);
        window.addEventListener('online', handleOnline);

        return () => {
            window.removeEventListener('error', handleNetworkError);
            window.removeEventListener('offline', handleNetworkError);
            window.removeEventListener('online', handleOnline);
        };
    }, []);

    useEffect(() => {
        let loader = document.querySelector("#video_loader");
        if (loader) {
            if (loading) {
                setTimer(prevTimer => {
                    prevTimer && clearTimeout(prevTimer);
                    return null;
                })
                loader.style.display = "flex";
                loader.style.opacity = 1;
            } else {
                if (domain === 'annamoney' && process.env.REACT_APP_GA_ANNAMONEY_ID) {
                    gtag('config', process.env.REACT_APP_GA_ANNAMONEY_ID);
                }

                if (domain === 'enjoyhannover' && process.env.REACT_APP_GA_ENJOYHANNOVER_ID) {
                    gtag('config', process.env.REACT_APP_GA_ENJOYHANNOVER_ID);
                }

                if (domain === 'columbiaalumni' && process.env.REACT_APP_GA_COLUMBIAALUMNI_ID) {
                    gtag('config', process.env.REACT_APP_GA_COLUMBIAALUMNI_ID);
                }

                if (domain === 'sfeducation' && process.env.REACT_APP_GA_SFEDUCAION_ID) {
                    gtag('config', process.env.REACT_APP_GA_SFEDUCAION_ID);
                }

                if (domain === 'passeio' && process.env.REACT_APP_GA_PASSEIO_ID) {
                    gtag('config', process.env.REACT_APP_GA_PASSEIO_ID);
                }

                gtag('event', 'loaded', {
                    community: domain
                })
                loader.style.opacity = 0;
                setTimer(setTimeout(() => {
                    loader.remove();
                }, 50))
            }
        }
    }, [loading, setTimer, domain]);


    const handleNetworkError = (event) => {
        if (notificationKey.current) {
            return;
        }
        const message = navigator.onLine
            ? 'Something is broken. Try to reload the page '
            : 'The app is offline. Check your connection ';

        const title = navigator.onLine
            ? 'Oops!'
            : 'Offline';
        const key = showNotification(title, message, 'default', 500000);
        notificationKey.current = key;
    };

    const handleOnline = () => {
        if (notificationKey.current) {
            hideNotification(notificationKey.current);
            notificationKey.current = null;
        }
    };

    return (
        <>
            <Page />
            <Suspense fallback={<></>}>
                <RedirectSuccess domain={domain} />
            </Suspense>
            <Suspense fallback={<></>}>
                <LazySentry />
            </Suspense>
        </>
    )
};

export default App
